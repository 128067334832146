<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Nueva Apertura de Propuestas</h1>
        <hr class="">
        <br>
        <h2>Oficio de vigilancia</h2>

        <v-form ref="formOficioVigilancia" class="mt-10" @submit.prevent="submitFormOficioVigilancia">
          <v-row class="mb-4">
            <v-expansion-panels inset>
              <v-expansion-panel
              >
                <v-expansion-panel-header>Número de Oficio</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12">
                    <h1 class="mt-4 mx-2 ">Número de Oficio</h1>
                    <trumbowyg
                        v-model="dataSurveillance.numero_oficio"
                        name="body"
                        :config="configs.advanced"
                        placeholder="Número de Oficio"
                        class="form-control"
                    ></trumbowyg>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
              >
                <v-expansion-panel-header>Hora y Minutos</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12">
                    <h1 class="mt-4 mx-2 ">Hora y Minutos</h1>
                    <trumbowyg
                        v-model="dataSurveillance.hora_minutos"
                        name="body"
                        :config="configs.advanced"
                        placeholder="Hora y Minutos"
                        class="form-control"
                    ></trumbowyg>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
              >
                <v-expansion-panel-header>Fecha en Letra</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12">
                    <h1 class="mt-4 mx-2 ">Fecha en Letra
                    </h1>
                    <trumbowyg
                        v-model="dataSurveillance.fecha_letra"
                        name="body"
                        :config="configs.advanced"
                        placeholder="Fecha en Letra"
                        class="form-control"
                    ></trumbowyg>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-col cols="6" class="mt-4">
              <v-btn type="submit" color="primary" style="width: 200px">
                Guardar
              </v-btn>
            </v-col>

          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'

export default {
  name: "Tenders",
  data() {
    return {
      overlay: false,
      dataSurveillance: {
        hora_minutos: "",
        fecha_letra: "",
        numero_oficio: "",
        formatosId: 25,
        tenderId: null,
      },
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            ['fullscreen'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
      data: {
        tenderId: null,
        formatId: null,
        numero_participantes: "",
        fecha: "",
        numero_sobres: "",
        licitantes: '',
        pie_acta: '',
        area_requirente: '',
        minutos: '',
        responsibility: '',
        conditionsPurchase: '',
      },
    };
  },
  components: {
    Overlay,
    Trumbowyg
  },
  created() {
    this.dataSurveillance.tenderId = this.$route.params.id;

  },
  methods: {
    ...mapActions('openingProposals', ['createOpeningProposals']),
    ...mapActions('ja_surveillance_unit', ['createJASurveillanceUnit']),
    async validateRequired() {
      if (!this.data.numero_participantes) {
        this.sweetAlertWarning('Campo Número Participantes es necesario');
        return false
      }
      if (!this.data.numero_sobres) {
        this.sweetAlertWarning('Campo Número de Sobres requerido');
        return false
      }
      if (!this.data.licitantes) {
        this.sweetAlertWarning('Campo Licitantes es requerido');
        return false
      }
      if (!this.data.pie_acta) {
        this.sweetAlertWarning('Campo Pie Acta es requerido');
        return false
      }
      if (!this.data.fecha) {
        this.sweetAlertWarning('Campo Fecha es requerido');
        return false
      }
      if (!this.data.area_requirente) {
        this.sweetAlertWarning('Campo Área Requirente es requerido');
        return false
      }
      if (!this.data.minutos) {
        this.sweetAlertWarning('Campo Minutos es requerido');
        return false
      }

      return true

    },
    async validateRequiredOficioVigilancia() {
      if (!this.dataSurveillance.numero_oficio) {
        this.sweetAlertWarning('Campo Número de Oficio es necesario');
        return false
      }
      if (!this.dataSurveillance.hora_minutos) {
        this.sweetAlertWarning('Campo Hora y Minutos es necesario');
        return false
      }
      if (!this.dataSurveillance.fecha_letra) {
        this.sweetAlertWarning('Campo Fecha en Letra es requerido');
        return false
      }
      return true
    },
    async submitFormOficioVigilancia() {
      const validateData = await this.validateRequiredOficioVigilancia();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.createJASurveillanceUnit(this.dataSurveillance);
      if (!response.error) {
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.dataSurveillance.tenderId}`);
      } else {
        this.sweetAlertError('Error al crear')
      }

      this.overlay = false;
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
